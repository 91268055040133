
<template>
  <div>

          <h3 >报名说明</h3>
          <p>1. 2025年上海国际象棋文化节 —— 上海市国际象棋棋协大师精英赛的报名截止日期为3月20日。</p>
          <p>2. 参赛资格为获得上海棋协大师称号的棋手，报名时必须上传棋协大师称号证书，样本如下图所示。</p>
          <img src="../assets/sample.jpg" style="width: 300px">
          <p>3. 比赛地点为上海智慧湾会议中心（宝山区蕰川路6号）。</p>
          <p>4. 参赛费为200元人民币。</p>
          <p>5. 比赛共分为六组，男、女A组为2010年12月31日（含）以前出生的棋手，男、女B组为2011年1月1日-2013年12月31日（含）出生，男、女C组为2014年1月1日（含）之后出生。</p>
          <p>6. 男子组各组别限报120人，女子组各组别限报80人。男子A组，男子B组，女子A组比赛时间为2025年3月29日，男子C组，女子B组，女子C组为3月30日</p>
          <p>7. 其他未尽事宜，请查看<a href="/zhangcheng2.pdf"  target="_blank">竞赛规程</a>，一旦报名即代表知晓并同意本次比赛竞赛规程。</p>
    <h1 style="color: red">报名已结束</h1>

  </div>
<!--  <div>-->
<!--    <div>-->
<!--      <h3 >报名说明</h3>-->
<!--      <p>1. 2025年上海国际象棋文化节 —— 上海市国际象棋棋协大师精英赛的报名截止日期为3月20日。</p>-->
<!--      <p>2. 参赛资格为获得上海棋协大师称号的棋手，报名时必须上传棋协大师称号证书，样本如下图所示。</p>-->
<!--      <img src="../assets/sample.jpg" style="width: 300px">-->
<!--      <p>3. 比赛地点为上海智慧湾会议中心（宝山区蕰川路6号）。</p>-->
<!--      <p>4. 参赛费为200元人民币。</p>-->
<!--      <p>5. 比赛共分为六组，男、女A组为2010年12月31日（含）以前出生的棋手，男、女B组为2011年1月1日-2013年12月31日（含）出生，男、女C组为2014年1月1日（含）之后出生。</p>-->
<!--      <p>6. 男子组各组别限报120人，女子组各组别限报80人。男子A组，男子B组，女子A组比赛时间为2025年3月29日，男子C组，女子B组，女子C组为3月30日</p>-->
<!--      <p>7. 其他未尽事宜，请查看<a href="/zhangcheng2.pdf"  target="_blank">竞赛规程</a>，一旦报名即代表知晓并同意本次比赛竞赛规程。</p>-->

<!--      <p style="color: red">【2025年3月6日 17:00更新】 目前我们发现中国移动的手机号可能无法接受短信验证码，我们已经与运营商协调备案，请您更换其他运营商的手机号或者耐心等待1~3个工作日。</p>-->
<!--    </div>-->

<!--    <div>-->
<!--      <h3>填写报名信息</h3>-->
<!--      <el-form ref="form" :model="registerForm" :rules="registerRule" label-width="180px">-->


<!--        <el-form-item prop="name" label="参赛选手姓名">-->
<!--          <el-input v-model="registerForm.name" placeholder="请输入报名人员姓名" prefix-icon="fas fa-user"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="idNumber" label="身份证号,X大写">-->
<!--          <el-input v-model="registerForm.idNumber"  placeholder="请输入报名人员身份证号，X请大写" prefix-icon="fas fa-id-card"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item >-->
<!--          <p>{{groupInfo}}</p>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="group" label="请选择分组">-->
<!--          <el-radio-group v-model="registerForm.group">-->
<!--            <el-radio style="font-size: 30px" v-for="item in contestList"   :label="item.id" :value="item.id" :disabled="item.limitp-5-item.num<=0">{{item.name }}  剩余可报名{{item.limitp-5-item.num}}人</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->


<!--        <el-form-item prop="username" label="手机号">-->
<!--          <el-input v-model="registerForm.username" placeholder="请输入手机号" prefix-icon="fas fa-mobile-alt"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="checkCode">-->
<!--          <el-col :span="12">-->
<!--            <el-input v-model="registerForm.checkCode" placeholder="请输入验证码" prefix-icon="fas fa-check-circle"></el-input>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-button block :disabled="checkCodeBtn!=='点击获得验证码'" @click="sms(registerForm.username)">{{checkCodeBtn}}</el-button>-->
<!--          </el-col>-->
<!--        </el-form-item>-->


<!--        <el-form-item prop="photo" label="证书照片">-->
<!--          <img  v-if='registerForm.photo' :src="baseUrl+'/'+registerForm.photo" class="avatar" style="padding-bottom: 20px;width: 400px">-->
<!--          <el-upload-->
<!--              class="avatar-uploader"-->
<!--              :action="baseUrl+'upload'"-->
<!--              :show-file-list="false"-->
<!--              :multiple="false"-->
<!--              :with-credentials="true"-->
<!--              :on-success="handleAvatarSuccess"-->
<!--              :before-upload="beforeAvatarUpload"-->
<!--              :on-error	="handleAvatarError">-->
<!--            <el-button size="small" type="primary" class="teal">请上传上海棋协大师称号证书照片</el-button>-->
<!--            <p style="color: grey;font-size: 10px">只支持jpg或png格式，大小不可超过10MB</p>-->

<!--          </el-upload>-->
<!--        </el-form-item>-->

<!--        <el-form-item>-->
<!--          我已经确认我的信息无误，符合报名的要求：-->
<!--          <el-switch-->
<!--              v-model="check"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949">-->
<!--          </el-switch>-->
<!--        </el-form-item>-->

<!--        <el-form-item>-->
<!--          <el-button  type="primary"  @click="order" :disabled="!check">提交报名信息</el-button>-->
<!--        </el-form-item>-->





<!--      </el-form>-->
<!--    </div>-->

<!--      <el-form-item label="邮寄地址">-->
<!--        <el-input v-model="form.address"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <el-button type="primary" class="teal" @click="save">点击保存修改</el-button>-->
<!--      </el-form-item>-->



<!--    <div class="progress">-->
<!--      <div class="sub" :class="{sub_active:active(1)}">-->
<!--        1、确认个人信息-->
<!--      </div>-->
<!--      <div class="sub" :class="{sub_active:active(2)}">-->
<!--        2、确认场次-->
<!--      </div>-->
<!--      <div class="sub" :class="{sub_active:active(3)}">-->
<!--        3、确认报名内容-->
<!--      </div>-->
<!--      <div class="sub" :class="{sub_active:active(4)}">-->
<!--        4、支付报名费-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-show="step===1" class="form-wrap">-->
<!--      &lt;!&ndash;        <div class="avatar">&ndash;&gt;-->
<!--      &lt;!&ndash;            <img :src="'https://www.shchess.com/imgserver/'+Pform.photo">&ndash;&gt;-->
<!--      &lt;!&ndash;            <p>照片</p>&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      <table class="form">-->
<!--        <tr>-->
<!--          <td>手机号</td><td>{{Pform.username}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>报名人员姓名</td><td>{{Pform.name}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>邮寄地址（必须填写）</td><td>{{Pform.address}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>身份证号</td><td>{{Pform.idNumber}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>单位</td><td><span v-if="Pform.company!=='其他'">{{Pform.company}}</span><span v-else>{{Pform.companya}}</span></td>-->
<!--        </tr>-->
<!--      </table>-->
<!--      <br>-->
<!--      <br>-->
<!--      <br>-->
<!--      <br>-->
<!--      <el-button style="height:40px;width: 500px;margin-bottom: 30px" type="warning" @click="$router.push({ name: 'Information'})">信息需要修改</el-button>-->
<!--      <el-button  style="height:40px;width: 500px;margin: 0"  type="primary" class="teal" @click="step++"  :disabled="!Pform.address  ">信息无误，下一步</el-button>-->
<!--    </div>-->
<!--    <div v-show="step===2" class="form2">-->
<!--      <el-form label-width="80px" style="width: 400px">-->
<!--        <el-form-item label="报名场次">-->
<!--          <el-select @change="contestChange" v-model="Cform.contest_id" placeholder="请选择报名场次"  >-->
<!--            <el-option v-for="contest in contestList" :key="contest.id"  :label="contest.month+'月'+contest.day+'日'+contest.desc" :value="contest.id"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="报名分组">-->
<!--          <el-select @change="groupChange"  :value="Gform.name">-->
<!--            <el-option v-for="group in groupList"  :value="group" :label="group.name" :key="group.name"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <template  v-if="Gform.name">-->
<!--          &lt;!&ndash;            <el-form-item >&ndash;&gt;-->
<!--          &lt;!&ndash;                选择的分组：{{Gform.name}}&ndash;&gt;-->
<!--          &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
<!--          &lt;!&ndash;            <el-form-item >&ndash;&gt;-->
<!--          &lt;!&ndash;                如果升级成功，你的下一个级别是：{{Gform.next}}&ndash;&gt;-->
<!--          &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
<!--          &lt;!&ndash;            <el-form-item >&ndash;&gt;-->
<!--          &lt;!&ndash;                你的小组的开赛时间：{{Gform.time}}&ndash;&gt;-->
<!--          &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
<!--          <el-form-item >-->
<!--            备注：{{Gform.remark}}-->
<!--          </el-form-item>-->
<!--        </template>-->
<!--      </el-form>-->
<!--      <br>-->
<!--      <br>-->
<!--      <el-button style="height:40px;width: 200px;margin-bottom: 30px" type="warning" @click="step&#45;&#45;">上一步</el-button>-->
<!--      <el-button  style="height:40px;width: 200px;margin: 0;"      @click="step++"-->
<!--                  :disabled="!Cform.contest_id || !Gform.name ">-->
<!--        信息无误，下一步-->
<!--      </el-button>-->
<!--    </div>-->
<!--    <div v-show="step===3" class="form-wrap3">-->
<!--      &lt;!&ndash;        <div class="avatar">&ndash;&gt;-->
<!--      &lt;!&ndash;            <img :src="'https://www.shchess.com/imgserver/'+Pform.photo">&ndash;&gt;-->
<!--      &lt;!&ndash;            <p>照片</p>&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      <table class="form">-->
<!--        <tr>-->
<!--          <td>报名场次</td><td>{{contestd.year}}年{{contestd.month}}月{{contestd.day}}日{{contestd.desc}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>报名费</td><td>{{contestd.amount}}元人民币</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>分组</td><td>{{Gform.name}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>分组培训时间：</td><td>{{Gform.time}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>姓名</td><td>{{Pform.name}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>身份证号，</td><td>{{Pform.idNumber}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>手机号</td><td>{{Pform.username}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>单位</td><td><span v-if="Pform.company!=='其他'">{{Pform.company}}</span><span v-else>{{Pform.companya}}</span></td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>-->
<!--            点击滑块确认：-->
<!--          </td>-->
<!--          <td>-->
<!--            <el-switch-->
<!--                v-model="check2"-->
<!--                active-color="#13ce66"-->
<!--                inactive-color="#ff4949">-->
<!--            </el-switch>-->
<!--            我已经确认我的信息无误，符合报名的要求-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td>-->
<!--            点击滑块确认：-->
<!--          </td>-->
<!--          <td>-->
<!--            <el-switch-->
<!--                v-model="check"-->
<!--                active-color="#13ce66"-->
<!--                inactive-color="#ff4949">-->
<!--            </el-switch>-->
<!--            我已经知晓本次培训规程：<a :href="contestd.rule"  target="_blank">点击下载并查看</a>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </table>-->
<!--      <br>-->
<!--      <br>-->
<!--      <el-button style="height:40px;width: 200px;margin-bottom: 30px" type="warning" @click="step&#45;&#45;">上一步</el-button>-->
<!--      <el-button  style="height:40px;width: 200px;margin: 0;color:white" class="teal"  :disabled="!check || !check2" @click="order" > 确信信息无误，进行支付</el-button>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>

import {baseUrl} from '../../config.js'
import Promise from "promise";
export default {
  name: "Home",
  data(){
    return{

      registerForm: {
        username: "",
        name:"",
        idNumber:"",
        checkCode:"",
        photo:"",
      },
      checkCodeBtn:"点击获得验证码",
      registerRule: {
        username: [
          {required: true, message: "必须输入手机号", trigger: "blur"},
          {pattern:"^admin|([1][3,4,5,7,8][0-9]{9})$", message: "手机号格式不正确", trigger: "blur"}
        ],
        name: [
          { required: true, message: "必须输入姓名", trigger: "blur" },
        ],
        group: [
          { required: true, message: "必须选择分组", trigger: "blur" },
        ],
        photo: [
          { required: true, message: "必须上传证书照片", trigger: "blur" },
        ],
        idNumber: [
          { required: true, message: "必须输入身份证号", trigger: "blur" },
          {pattern:"^[1-9]\\d{5}(18|19|20|(3\\d))\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9X]$", message: "身份证号格式不正确", trigger: "blur"}
        ],
        checkCode: [
          { required: true, message: "必须输入验证码", trigger: "blur" },
          {pattern:"^\\d{6}$", message: "验证码为六位数字", trigger: "blur"}
        ]
      },
      check:false,

      baseUrl:baseUrl,
      contestList:[],


    }
  },
  methods:{
    sms(username){
      this.$http.postData("sms2",{username},
          (data)=>{
            this.$message.success("成功发送验证码")
            let time=60
            this.checkCodeBtn=time+"秒后重试"
            this.timer=setInterval( ()=> {
              this.checkCodeBtn=time+"秒后重试"
              if(time===0){
                this.checkCodeBtn="点击获得验证码"
                clearInterval(this.timer)
              }
              time--
            },1000)
          },
          (data)=>{
            this.$message.error(data.message);
          }
      )
    },

    handleAvatarSuccess(res, file) {
      this.$message.success("上传照片成功");
      this.registerForm.photo=res.filename;
      this.save()
    },
    handleAvatarError(err, file, fileList){
      this.$message.error("上传照片失败，请重试");
    },
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === "image/jpeg" ||
          file.type === "image/png" ;
      const isLt5M = file.size / 1024 / 1024 < 10;
      let that = this;
      let isAllow = false;
      if (!isJPG) {
        this.$message.error("上传照片只能是 jpg、png 格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传照片大小不能超过 10MB!");
      }
      const isSize = new Promise(function(resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.onload = function() {
          // let width = image.width;
          // let height = image.height;
          // if(height/width>1.7){
          //   reject("图片长宽比不符合要求，图片过长，请裁剪图片")
          // }else if(height/width<1.1){
          //   reject("图片长宽比不符合要求，图片过宽，请裁剪图片")
          // }else {
          //   resolve()
          // }
          resolve()
        };
        image.src = _URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          (err) => {
            this.$message.error(err);
            return Promise.reject();
          }
      );
      return isJPG && isLt5M && isSize;

    },

    order(){
      this.$http.postData("register2",this.registerForm,()=>{
        this.$message.success("操作成功，请继续完成支付");

        this.$router.push({name:'Information',params:{name:this.registerForm.name ,username:this.registerForm.username}})
      },(data)=>{
        this.$message.error(data.message);
      })
    },
    active(num){
      return num <= this.step;
    },
    groupChange(e){
      this.Gform=JSON.parse(JSON.stringify(e))
    },
    contestChange(e){
      this.Gform={}
    }
  },
  computed:{
    groupList:function () {
      for(let i=0;i<this.contestList.length;i++){
        if(this.contestList[i].id===this.Cform.contest_id){
          return JSON.parse(this.contestList[i].group)
        }
      }
      return []
    },
    contestd:function () {
      for(let i=0;i<this.contestList.length;i++){
        if(this.contestList[i].id===this.Cform.contest_id){
          return this.contestList[i]
        }
      }
      return {}
    },

    groupInfo:function () {
      let id =this.registerForm.idNumber
      let sex=""
      let year=""
      let month=""
      let day=""
      let fulld=""
      let group=""
      if(id.length!==18){
        return ""
      }else{
        year=id.substring(6,10)
        month = id.substring(10,12)
        day = id.substring(12,14)

        fulld = id.substring(6,14)

        if (parseInt(id[16]) % 2  ===1 ){
          sex = "男"
        }else if (parseInt(id[16]) % 2  ===0){
          sex = "女"
        }else{
          return "身份证号性别错误"
        }

        if ( fulld <= "20101231"){
          group="A"
        }else if ( fulld <= "20131231" && fulld >= "20110101" ){
          group="B"
        }else if (  fulld >= "20140101" ){
          group="C"
        }else{
          return "日期错误"
        }

        return  `你的性别是${sex},${year}年${month}月${day}日出生，应该报名 ${sex}子${group}组`
      }

    }
  },
  mounted() {


    // this.$http.getData("information",(data)=>{
    //   this.Pform=data
    // },()=>{
    //   this.$message.error("获取个人信息错误")
    // })


    this.$http.getData("contest2",(data)=>{
      this.contestList=data



    },()=>{
      this.$message.error("获取比赛信息错误")
    })
  }
}
</script>

<style scoped lang="scss">
.form2{
  padding: 50px;
}
.form-wrap3{
  position: relative;
  text-align: center;
  .avatar{
    margin: 10px;
    img{
      width: 200px;
    }
    position: absolute;
    left: 650px;

  }
  .form{
    font-size: 15px;
    text-align: start;
    display: inline-block;
    td{
      padding: 10px;
    }
  }
}
.form-wrap{
  text-align: center;
  .avatar{
    margin: 10px;
    img{
      width: 200px;
    }
    float:right;

  }
  .form{
    font-size: 30px;
    text-align: start;
    display: inline-block;
    td{
      padding: 20px;
    }
  }
}
.progress{
  display: flex;
  .sub{
    border: 2px #000000 solid;
    text-align: center;
    flex: 1;
    font-size: 17px;
    height: 50px;
    line-height: 50px;
  }
  .sub_active{
    background-color: #000064;
    color: white;

  }
}
</style>









<!--<template>-->
<!--    <ul class="contestList">-->
<!--        <li class="contest" v-for="contest in contestList" :class="{'active':contest.status===2,'over':contest.status===3}">-->
<!--            <div class="contest_head">-->
<!--                <p>{{contest.year}}年<em>{{contest.month}}</em>月</p>-->
<!--                <p class="type">{{contest.desc}}</p>-->
<!--                <span v-if="contest.status===1">暂未报名</span>-->
<!--                <span v-else-if="contest.status===2">报名中>>>></span>-->
<!--                <span v-else>报名已结束</span>-->
<!--            </div>-->
<!--            <div class="contest_detail">-->
<!--                <div class="detail_main">-->
<!--                    <p class="date">{{contest.year}}年<em>{{contest.month}}</em>月<em >{{contest.day}}</em>日举办</p>-->
<!--                    <p>地址：{{contest.address}}</p>-->
<!--                    <p class="main_button">-->
<!--                        <el-button  v-if="contest.status===1" style="width: 100%;background: #000064;color: white" round>-->
<!--                            暂未报名-->
<!--                        </el-button>-->
<!--                        <el-button  v-else-if="contest.status===2" style="width: 100%;background: #d75a00;color: white" round @click="$router.push({name:'Register'})">-->
<!--                            报名中-->
<!--                        </el-button>-->
<!--                        <el-button v-else style="width: 100%;background: #666;color: white" round>-->
<!--                            报名已结束-->
<!--                        </el-button>-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="detail_sub">-->
<!--                    <div class="sub_title">-->
<!--                        <p>-->
<!--                            网上报名时间-->
<!--                        </p>-->
<!--                    </div>-->
<!--                    <div class="sub_body">-->
<!--                        <p>{{contest.syear}}年<em>{{contest.smonth}}</em>月<em>{{contest.sday}}</em>日<em>{{contest.stime}}</em> ～-->
<!--                            {{contest.eyear}}年<em>{{contest.emonth}}</em>月<em>{{contest.eday}}</em>日<em>{{contest.etime}}</em></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </li>-->
<!--    </ul>-->


<!--</template>-->

<!--<script>-->
<!--// @ is an alias to /src-->
<!--export default {-->
<!--  name: 'Home',-->
<!--    data(){-->
<!--        return{-->
<!--            contestList:[]-->
<!--        }-->
<!--    },-->
<!--  mounted() {-->
<!--      this.$http.getData('icontest',(data)=>{-->
<!--            this.contestList=data-->
<!--          },()=>{-->
<!--          this.$message.error("获取比赛信息失败，服务器维护中");-->
<!--      })-->
<!--  }-->
<!--}-->
<!--</script>-->
<!--<style lang="scss" scoped>-->
<!--    .contestList{-->
<!--        list-style: none;-->
<!--        margin: 0;-->
<!--        padding:0;-->
<!--        .contest {-->
<!--            overflow: hidden;-->
<!--            display: table;-->
<!--            margin-top: 15px;-->
<!--            padding: 0;-->
<!--            width: 100%;-->
<!--            border-radius: 5px;-->
<!--            color: #222;-->
<!--            font-size: 1em;-->
<!--            text-align: center;-->
<!--            vertical-align: middle;-->
<!--            -webkit-box-sizing: border-box;-->
<!--            box-sizing: border-box;-->
<!--            .contest_head{-->
<!--                display: table-cell;-->
<!--                width: 250px;-->
<!--                margin: 0;-->
<!--                padding: 23px 22px 30px;-->
<!--                border-radius: 5px 0 0 5px;-->
<!--                background: #000064;-->
<!--                color: #fff;-->
<!--                font-size: 20px;-->
<!--                vertical-align: middle;-->
<!--                line-height: 1.2;-->
<!--                -webkit-box-sizing: border-box;-->
<!--                box-sizing: border-box;-->
<!--                p{-->
<!--                    margin-bottom: 5px;-->
<!--                }-->
<!--                em{-->
<!--                    font-style: normal;-->
<!--                    margin: 0 5px;-->
<!--                    font-size: 1.6em;-->
<!--                    font-weight: bold;-->
<!--                }-->
<!--                span{-->
<!--                    display: block;-->
<!--                    margin-top: 20px;-->
<!--                    padding: 4px 3px;-->
<!--                    border: 1px solid #fff;-->
<!--                    font-size: 0.667em;-->
<!--                    font-weight: bold;-->
<!--                }-->
<!--            }-->
<!--            .contest_detail{-->
<!--                display: table-cell;-->
<!--                border: 4px solid #bbc8e6;-->
<!--                border-left: none;-->
<!--                border-radius: 0 5px 5px 0;-->
<!--                text-align: left;-->
<!--                vertical-align: middle;-->
<!--                background: white;-->
<!--                .detail_main{-->
<!--                    display: table;-->
<!--                    width: 100%;-->
<!--                    padding: 17px 35px 17px 30px;-->
<!--                    -webkit-box-sizing: border-box;-->
<!--                    box-sizing: border-box;-->

<!--                    .date{-->
<!--                        margin-top: 0;-->
<!--                        font-size: 30px;-->
<!--                        line-height: 1.2;-->
<!--                        em{-->
<!--                            font-size: 1.5em;-->
<!--                            font-style: normal;-->
<!--                            font-weight: bold;-->
<!--                        }-->
<!--                    }-->
<!--                    .main_button{-->
<!--                        display: table-cell;-->
<!--                        width: 240px;-->
<!--                        vertical-align: middle;-->
<!--                    }-->
<!--                }-->
<!--                .detail_sub{-->
<!--                    display: table;-->
<!--                    width: 100%;-->
<!--                    margin-top: -7px;-->
<!--                    padding: 15px 30px;-->
<!--                    background: #eef1f6;-->
<!--                    -webkit-box-sizing: border-box;-->
<!--                    box-sizing: border-box;-->
<!--                    .sub_title{-->
<!--                        p{-->
<!--                            padding: 7px 0 7px 15px;-->
<!--                            background: #fff;-->
<!--                            font-size: 18px;-->
<!--                            line-height: 1.4;-->
<!--                        }-->
<!--                        &:after{-->
<!--                            position: absolute;-->
<!--                            top: 0;-->
<!--                            right: 0;-->
<!--                            width: 16px;-->
<!--                            height: 100%;-->
<!--                            background: url(../assets/schedule-vertical_bg01.png) no-repeat right center;-->
<!--                            content: "";-->
<!--                        }-->
<!--                        position: relative;-->
<!--                        display: table-cell;-->
<!--                        width: 131px;-->
<!--                        padding-right: 15px;-->
<!--                        vertical-align: middle;-->
<!--                    }-->
<!--                    .sub_body{-->
<!--                        display: table-cell;-->
<!--                        padding-left: 18px;-->
<!--                        vertical-align: middle;-->
<!--                        em{-->
<!--                            font-size: 1.428em;-->
<!--                            font-style: normal;-->
<!--                            font-weight: bold;-->
<!--                        }-->
<!--                    }-->
<!--                }-->
<!--            }-->
<!--        }-->

<!--        .over{-->
<!--            .contest_head{-->
<!--                background: #666;-->
<!--            }-->
<!--        }-->
<!--        .active{-->
<!--            .contest_head{-->
<!--                background: #d75a00 !important;-->
<!--            }-->
<!--        }-->

<!--    }-->



<!--</style>-->
