<template>
  <div id="app">
      <el-dialog title="常见问题" :visible.sync="Qdialog" :close-on-click-modal="false">
          <p>有问题请联系客服电话：1-7-3-1-7-1—9-7-5-2-1 （去掉数字中的横线）</p>
          <div slot="footer" class="dialog-footer">
              <el-button @click="Qdialog = false">关闭对话框</el-button>
          </div>
      </el-dialog>
    <header>
        <div class="line"></div>
        <div class="header_inner" >
            <img src="./assets/shchesscom.png" style="width: 100px;position: relative;top:5px;margin-right: 20px">
           <div style="display: inline-block">
             <span class="blue"  style="font-size: 30px">上海市<span class="orange">国际象棋</span>棋协大师精英赛报名网站</span><br>
             <span class="blue"  style="font-size: 40px">shchess.<span class="orange">com</span></span>
           </div>

<!--            <p class="blue" style="font-size: 25px"><span>上 棋</span></p>-->
<!--            <div class="meanu">-->
<!--                <el-button  type="warning" icon="el-icon-search" @click="Qdialog=true">常见问题</el-button>-->
<!--                <el-divider direction="vertical"></el-divider>-->
<!--                <router-link to="/login" v-if="!isLogin"><el-button  type="warning" icon="el-icon-user">注册/登录</el-button></router-link>-->
<!--                <el-button v-else type="info" icon="el-icon-user" @click="logout">你已经登录，点击退出登录</el-button>-->

<!--            </div>-->
        </div>
    </header>
      <div class="nav_menu_warp">
          <div class="nav_menu" >

              <div class="nav" >
                      <router-link to="/" class="white"  :class="{lightorange:$route.name==='Home'}">报名</router-link>
              </div>

              <div class="nav">
                  <router-link to="/information" class="white"  :class="{lightorange:$route.name==='Information'}">查看报名情况</router-link>
              </div>
<!--            <div class="nav">-->
<!--              <router-link to="/register" class="white"  :class="{lightorange:$route.name==='Register'}">报名</router-link>-->
<!--            </div>-->
<!--              <div class="nav">-->
<!--                  <router-link to="/history" class="white"  :class="{lightorange:$route.name==='History'}">报名记录</router-link>-->
<!--              </div>-->

          </div>
      </div>
      <div class="content-warp" >
          <router-view></router-view>
      </div>
      <footer>
          <p>联系电话：1-7-3-1-7-1—9-7-5-2-1 （去掉数字中的横线）</p>
      </footer>
  </div>
</template>
<script>

    export default {
        data(){
            return{
                Qdialog:false,
                isLogin:false
            }
        },
        mounted() {

          // this.$http.getData('logintest',()=>{
          //   this.isLogin=true
          // },()=>{
          //   this.isLogin=false
          // })
          // // this.$http.getData("logout", ()=>{}, ()=>{})
          //   // if( /token=.{30,};*?/.test(document.cookie)){
          //   //     this.isLogin=true
          //   // }
          //   this.$bus.on("loginsuccess",(data)=>{
          //       window.sessionStorage.setItem("token", data.token);
          //       this.$message.success("登录成功");
          //       this.$router.push({ name: 'Home'})
          //       this.isLogin=true
          //   });
        },
        methods:{
            logout:function(){
              this.$http.getData("logout", ()=>{}, ()=>{})
              window.sessionStorage.clear()
                this.$message.success("退出登录成功");
                this.$router.push({ name: 'Home'})
                this.isLogin=false

            },
            test:function () {
                console.log(this.$route.name)
            }
        }
    }
</script>
<style>
body{
     background: url('./assets/bg.gif');
}
    .teal{
       background-color: rgb(0, 124, 137) !important;
    }
    .el-message{
        font-size: 25px !important;
    }
    .el-message__content{
        font-size: 25px !important;
    }
</style>
<style lang="scss" scoped>
    .blue{
        color: #000064 !important;
    }
    .orange{
         color:#d75a00 !important;
     }
    .lightorange{
        color: #ebb87c !important;
    }
    .white{
        color: white ;
    }
footer{
    p{
        margin: 0;
    }
    margin:0;
    z-index: 9999;
    text-align: center;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #000064;
    height: 70px;
    line-height: 70px;

}

header{
    margin-bottom: 5px;
    .line{
        position: absolute;
        top: 0;
        width: 100%;
        height: 6px;
        background: #000064;
    }

    /*&:before{*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    width: 100%;*/
    /*    height: 6px;*/
    /*    background: #000064;*/
    /*    content: "";*/
    /*}*/
    .header_inner{
        position: relative;
        width: 960px;
        margin: 0 auto;
        padding-top: 17px;
        /*&:before{*/
        /*    display: block;*/
        /*    position: absolute;*/
        /*    top: 0;*/
        /*    left: 0;*/
        /*    width: 120px;*/
        /*    height: 6px;*/
        /*    background: #d75a00;*/
        /*    content: "";*/
        /*}*/
    }
    .meanu{
        display: inline-block;
        position: absolute;
        top:50px;
        right: 0;
    }


}
    .nav_menu_warp{
        margin-top: 30px;
        margin-bottom: 30px;
        background: #000064;
        .nav_menu{
            width: 960px;
            margin: 0 auto;
            display: -webkit-flex;
            display: flex;
            .nav{
                border-left: 1px solid #373782;
                border-right: 1px solid #373782;
                text-align: center;
                padding:20px 12px;
                flex: 1;
                color: white;
                font-weight: bold;
            }
        }
    }
    .content-warp{
        width: 940px;
        margin: 0 auto 150px auto;
    }
</style>
