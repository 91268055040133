<template>
  <div>
<!--      <div style="text-align: center;margin-bottom: 30px">-->
<!--&lt;!&ndash;          <a :href="'https://www.shchess.com/imgserver/'+form.photo"  :download="'shchesscn'+Date.now()">下载</a>&ndash;&gt;-->
<!--          <img :src="baseUrl+'imgserver/'+form.photo" class="avatar" style="padding-bottom: 20px">-->
<!--          <el-upload-->
<!--              class="avatar-uploader"-->
<!--              :action="baseUrl+'imgserver/upload'"-->
<!--              :show-file-list="false"-->
<!--              :multiple="false"-->
<!--              :with-credentials="true"-->
<!--              :on-success="handleAvatarSuccess"-->
<!--              :before-upload="beforeAvatarUpload"-->
<!--              :on-error	="handleAvatarError">-->
<!--          <el-button size="small" type="primary" class="teal">点击上传新照片</el-button>-->
<!--          <p style="color: gray;font-size: 10px">只支持jpg或png格式，大小不可超过5MB，没有证件照请上传可以清晰看清脸的大头照</p>-->

<!--      </el-upload>-->

<!--      </div>-->

<!--      <el-form ref="form" :model="form" label-width="180px">-->
<!--          <el-form-item  style="color: grey;font-size: 10px">-->
<!--              *灰色部分不可自行修改，如需修改请点击上方常见问题，联系客服。-->
<!--          </el-form-item>-->
<!--          <el-form-item label="手机号">-->
<!--              <el-input v-model="form.username" disabled></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="姓名">-->
<!--              <el-input v-model="form.name" disabled></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="身份证号">-->
<!--              <el-input v-model="form.idNumber" disabled></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="单位">-->
<!--              <el-select v-model="form.company" placeholder="请选择单位">-->
<!--                  <el-option-->
<!--                          v-for="item in companyList"-->
<!--                          :key="item.company"-->
<!--                          :label="item.company"-->
<!--                          :value="item.company">-->
<!--                  </el-option>-->
<!--              </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="请补充单位名称" v-if="form.company==='其他'">-->
<!--              <el-input v-model="form.companya"></el-input>-->
<!--          </el-form-item>-->
<!--        <el-form-item label="邮寄地址">-->
<!--          <el-input v-model="form.address"></el-input>-->
<!--        </el-form-item>-->
<!--          <el-form-item>-->
<!--              <el-button type="primary" class="teal" @click="save">点击保存修改</el-button>-->
<!--          </el-form-item>-->
<!--      </el-form>-->

    <el-form   label-width="180px">
      <el-form-item  style="color: grey;font-size: 10px">
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="username" ></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="name" ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="teal" @click="save">查询报名信息</el-button>
      </el-form-item>
    </el-form>

    <div v-if="this.show">
      <div v-if="quy.paysta == 3 && quy.certsta == 3 ">
        <p>当前状态： 已报名成功，已确认缴费和参赛资格，请准时参赛</p>
        <p >请扫码加入下方微信群。</p>
        <img src="../assets/weg.jpg" style="width: 400px">
      </div>
      <div v-else-if="quy.status == 1">
        <p>当前状态： 已报名，待确认</p>
        <p style="color: red">请扫描下方二维码扫码付款，扫描后请务必正确填写联系电话，姓名，身份证号码，必须和报名系统中填写的一样。</p>
        <p style="color: red">支付后需要最多7个工作日确认您的支付和参赛资格，确认后这个页面的状态才会改变，请耐心等待，不要重复付款。</p>
        <p >请扫码加入下方微信群。</p>
        <img src="../assets/wepayqrcode.jpg" style="width: 400px"><img src="../assets/weg.jpg" style="width: 400px">

      </div>




      <p>身份证号: {{quy.idNumber}}</p>
      <div>
        <p>证书照片</p>
        <img :src="baseUrl+'/'+quy.photo" class="avatar" style="padding-bottom: 20px;width: 400px">
      </div>

      <p>报名分组： {{quy.conname}}</p>





    </div>
  </div>
</template>

<script>
    let Promise = require('promise');
    import {baseUrl} from '../../config.js'
    export default {
        name: "Information",
        data() {
            return {
              quy:{},

              show:false,

              username:'',
              name:'',
              baseUrl:baseUrl,
            }
        },
        methods: {
            save() {


                this.$http.postData("info2",{username:this.username,name:this.name},(data)=>{
                    this.quy=data
                  this.show=true
                  this.$message.success("查询报名成功");
                  console.log(this.quy)
                },(data)=>{
                    this.$message.error(data.message)
                })
            },
            handleAvatarSuccess(res, file) {
                this.$message.success("上传照片成功");
                this.form.photo=res.filename;
                this.save()
            },
            handleAvatarError(err, file, fileList){
                this.$message.error("上传照片失败，请重试");
            },
            beforeAvatarUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif";
                const isLt5M = file.size / 1024 / 1024 < 5;
                let that = this;
                let isAllow = false;
                if (!isJPG) {
                    this.$message.error("上传照片只能是 jpg、png、gif 格式!");
                }
                if (!isLt5M) {
                    this.$message.error("上传照片大小不能超过 5MB!");
                }
                const isSize = new Promise(function(resolve, reject) {
                    let _URL = window.URL || window.webkitURL;
                    let image = new Image();
                    image.onload = function() {
                        let width = image.width;
                        let height = image.height;
                        if(height/width>1.7){
                            reject("图片长宽比不符合要求，图片过长，请裁剪图片")
                        }else if(height/width<1.1){
                            reject("图片长宽比不符合要求，图片过宽，请裁剪图片")
                        }else {
                            resolve()
                        }
                    };
                    image.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    (err) => {
                        this.$message.error(err);
                        return Promise.reject();
                    }
                );
                 console.log()
                return isJPG && isLt5M && isSize;

            },
        },
        mounted() {

          if(this.$route.params.name){
            this.name = this.$route.params.name
            this.username= this.$route.params.username
            this.save()
          }
            // this.$http.getData("information",(data)=>{
            //     this.form=data
            // },()=>{
            //     this.$message.error("获取个人信息错误")
            // })
            // this.$http.getData("company",(data)=>{
            //     this.companyList=data
            // },()=>{
            //     this.$message.error("获取单位信息错误")
            // })

        },

    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar {
        width: 200px;
        display: inline-block;
    }
</style>

<style scoped>

</style>